import {
  ExportLayoutOptions,
  ExportPDFOptions as InternalExportPDFOptions,
  FitPages,
  PageOrientation,
  PageScalingOption,
  PageSizeOption,
  ScaleMode,
  ScalingSelection,
} from '@tableau/api-internal-contract-js';
import {
  ExportDataOptions,
  ExportPDFOptions as ExternalExportPDFOptions,
  PrintOrientation,
  PrintPageSize,
  PrintScaling,
  SharedErrorCodes,
} from '@tableau/api-external-contract-js';
import { TableauError } from '../TableauError';

/**
 * Helper methods for Export APIs.
 *
 * @class ExportHelpers
 */
export class ExportHelpers {
  public static DefaultDataOptions: ExportDataOptions = {
    ignoreAliases: false,
    columnsToIncludeById: [],
  };

  public static DefaultPDFOptions: ExternalExportPDFOptions = {
    scaling: PrintScaling.Automatic,
    pageSize: PrintPageSize.Letter,
    orientation: PrintOrientation.Portrait,
  };

  private static DefaultLayoutOptions: ExportLayoutOptions = {
    imageHeight: 0,
    imageWidth: 0,
    pageFitHorizontal: 1,
    pageFitVertical: 1,
    pageOrientationOption: PageOrientation.Portrait,
    pageScaleMode: ScaleMode.ScaleAuto,
    pageScalePercent: 100,
    pageSizeOption: PageSizeOption.Letter,
  };

  /**
   * Modifies the internal ExportPDFOptions' properties based on the given PDF export configuration.
   * This method will construct the internal ExportPDFOptions identically to how the 'Export PDF' dialog constructs the ExportPdfOptionsPresModel.
   * See the handleExportClick method in @browser-clients/export-pdf-options-dialog for reference.
   *
   * @param internalExportPdfOptions The internal ExportPDFOptions pres model used to configure the output PDF file.
   * @param externalExportPdfOptions The external ExportPDFOptions user-facing object passed to exportPDFAsync.
   * @param sheetNames The list of sheets selected for export.
   */
  public static updateInternalExportPDFOptions(
    internalExportPdfOptions: InternalExportPDFOptions,
    externalExportPdfOptions: ExternalExportPDFOptions,
    sheetNames: Array<string>,
  ): void {
    internalExportPdfOptions.currentSheet = sheetNames[0];
    this.updateExportLayoutOptions(internalExportPdfOptions, externalExportPdfOptions);
    this.updateSheetOptions(internalExportPdfOptions, sheetNames);
  }

  /**
   * Helper method for updateInternalExportPDFOptions.
   * Modifies the exportLayoutOptions property of the internal ExportPDFOptions based on the external ExportPDFOptions.
   * See the getExportLayoutOptions method in @browser-clients/export-pdf-options-dialog for reference.
   *
   * @param internalExportPdfOptions The internal ExportPDFOptions pres model used to configure the output PDF file.
   * @param externalExportPdfOptions The external ExportPDFOptions user-facing object passed to exportPDFAsync.
   */
  public static updateExportLayoutOptions(
    internalExportPdfOptions: InternalExportPDFOptions,
    externalExportPdfOptions: ExternalExportPDFOptions,
  ): void {
    const exportLayoutOptions = internalExportPdfOptions.exportLayoutOptions;

    // setting the image height and width properties to their default values
    exportLayoutOptions.imageHeight = this.DefaultLayoutOptions.imageHeight;
    exportLayoutOptions.imageWidth = this.DefaultLayoutOptions.imageWidth;

    //  setting the orientation value for the exportLayoutOptions
    switch (externalExportPdfOptions.orientation) {
      case PrintOrientation.Landscape:
        exportLayoutOptions.pageOrientationOption = PageOrientation.Landscape;
        break;
      case PrintOrientation.Portrait:
        exportLayoutOptions.pageOrientationOption = PageOrientation.Portrait;
        break;
      default:
        throw new TableauError(SharedErrorCodes.InternalError, 'invalid orientation for ExportPDFOption');
    }

    // setting the page size value for the exportLayoutOptions
    switch (externalExportPdfOptions.pageSize) {
      case PrintPageSize.A3:
        exportLayoutOptions.pageSizeOption = PageSizeOption.A3;
        break;
      case PrintPageSize.A4:
        exportLayoutOptions.pageSizeOption = PageSizeOption.A4;
        break;
      case PrintPageSize.A5:
        exportLayoutOptions.pageSizeOption = PageSizeOption.A5;
        break;
      case PrintPageSize.B4:
        exportLayoutOptions.pageSizeOption = PageSizeOption.B4;
        break;
      case PrintPageSize.B5:
        exportLayoutOptions.pageSizeOption = PageSizeOption.B5;
        break;
      case PrintPageSize.Executive:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Executive;
        break;
      case PrintPageSize.Folio:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Folio;
        break;
      case PrintPageSize.Ledger:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Ledger;
        break;
      case PrintPageSize.Legal:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Legal;
        break;
      case PrintPageSize.Letter:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Letter;
        break;
      case PrintPageSize.Note:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Note;
        break;
      case PrintPageSize.Quarto:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Quarto;
        break;
      case PrintPageSize.Statement:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Statement;
        break;
      case PrintPageSize.Tabloid:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Tabloid;
        break;
      case PrintPageSize.Unspecified:
        exportLayoutOptions.pageSizeOption = PageSizeOption.Unspecified;
        break;
      default:
        throw new TableauError(SharedErrorCodes.InternalError, 'invalid pageSize for ExportPDFOption');
    }

    // setting the scaling values for the exportLayoutOptions
    switch (externalExportPdfOptions.scaling) {
      case PrintScaling.AtMost1PageHigh:
        this.setFitAndScaleValues(exportLayoutOptions, FitPages.FitOneHigh);
        break;
      case PrintScaling.AtMost1PageWide:
        this.setFitAndScaleValues(exportLayoutOptions, FitPages.FitOneWide);
        break;
      case PrintScaling.AtMost2PagesHigh:
        this.setFitAndScaleValues(exportLayoutOptions, FitPages.FitTwoHigh);
        break;
      case PrintScaling.AtMost2PagesWide:
        this.setFitAndScaleValues(exportLayoutOptions, FitPages.FitTwoWide);
        break;
      case PrintScaling.Automatic:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePctAuto);
        break;
      case PrintScaling.Perc100:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct100);
        break;
      case PrintScaling.Perc200:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct200);
        break;
      case PrintScaling.Perc25:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct25);
        break;
      case PrintScaling.Perc400:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct400);
        break;
      case PrintScaling.Perc50:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct50);
        break;
      case PrintScaling.Perc60:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct60);
        break;
      case PrintScaling.Perc75:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct75);
        break;
      case PrintScaling.Perc80:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct80);
        break;
      case PrintScaling.Perc90:
        this.setFitAndScaleValues(exportLayoutOptions, PageScalingOption.ScalePct90);
        break;
      default:
        throw new TableauError(SharedErrorCodes.InternalError, 'invalid scaling for ExportPDFOption');
    }
  }

  /**
   * Helper method for updateInternalExportPDFOptions.
   * Modifies the sheetOptions property of the internal ExportPDFOptions based on the sheets selected for export.
   * See the handleExportClick method in @browser-clients/export-pdf-options-dialog for reference.
   *
   * @param internalExportPdfOptions The internal ExportPDFOptions pres model used to configure the output PDF file.
   * @param sheetNames The list of sheets selected for export.
   */
  public static updateSheetOptions(internalExportPdfOptions: InternalExportPDFOptions, sheetNames: Array<string>): void {
    const { exportLayoutOptions, sheetOptions } = internalExportPdfOptions;
    // updating the export layouts options for each sheet and selecting sheet for export if included in list
    for (const sheetOption of sheetOptions) {
      sheetOption.exportLayoutOptions = exportLayoutOptions;
      sheetOption.isSelected = sheetNames.includes(sheetOption.sheet);
    }
  }

  /**
   * Helper method for updateExportLayoutOptons.
   * This method is a spoof of the getFitAndScaleValues method used in @browser-clients/export-pdf-options to set the exportLayoutOptions' scaling properties.
   * In the future, this file should be refactored to import the method directly from @browser-clients/export-pdf-options.
   *
   * @param scalingSelection string used for setting the exportLayoutOptions based on the PrintScaling property from the ExternalExportPDFOptions.
   * @param exportLayoutOptions the ExportLayoutOptions used by the internal ExportPDFOptions pres model.
   */
  public static setFitAndScaleValues(exportLayoutOptions: ExportLayoutOptions, scalingSelection: ScalingSelection): void {
    let { pageFitHorizontal, pageFitVertical, pageScaleMode, pageScalePercent } = this.DefaultLayoutOptions;

    const fitMatch = this.fitRegex.exec(scalingSelection);
    const percentMatch = this.pctRegex.exec(scalingSelection);

    if (fitMatch) {
      [pageFitHorizontal, pageFitVertical] = fitMatch.slice(1).map((value) => Number(value));
      pageScaleMode = ScaleMode.ScaleFitPages;
    } else if (percentMatch) {
      [pageScalePercent] = percentMatch.slice(1).map((value) => Number(value));
      pageScaleMode = ScaleMode.ScalePercentage;
    }

    // getFitAndScaleValues returns the properties below, but instead this method will set them.
    exportLayoutOptions.pageFitHorizontal = pageFitHorizontal;
    exportLayoutOptions.pageFitVertical = pageFitVertical;
    exportLayoutOptions.pageScaleMode = pageScaleMode;
    exportLayoutOptions.pageScalePercent = pageScalePercent;
  }

  private static fitRegex = new RegExp('fit([0-9]+)x([0-9]+)');
  private static pctRegex = new RegExp('pct([0-9]+)');
}
