import {
  AnnotationType as ExternalAnnotationType,
  ClassNameKey as ExternalClassNameKey,
  ColumnType as ExternalColumnType,
  DashboardObjectType as ExternalDashboardObjectType,
  DataType as ExternalDataType,
  PeriodType as ExternalDatePeriod,
  DateRangeType as ExternalDateRangeType,
  ErrorCodes as ExternalErrorCodes,
  ExtensionContext as ExternalExtensionsContext,
  ExtensionMode as ExternalExtensionsMode,
  FieldAggregationType as ExternalFieldAggregationType,
  FieldRoleType as ExternalFieldRoleType,
  FilterType as ExternalFilterType,
  FilterUpdateType as ExternalFilterUpdateType,
  HierarchicalLevelSelectionState as ExternalHierarchicalLevelSelectionState,
  MarkType as ExternalMarkType,
  ParameterValueType as ExternalParameterValueType,
  SheetType as ExternalSheetType,
} from '@tableau/api-external-contract-js';
import {
  AnnotateEnum as InternalAnnotationType,
  ClassNameKey as InternalClassNameKey,
  ColumnType as InternalColumnType,
  DashboardObjectType as InternalDashboardObjectType,
  DataType as InternalDataType,
  DateRangeType as InternalDateRangeType,
  DateStepPeriod as InternalDateStepPeriod,
  DomainRestrictionType as InternalDomainRestrictionType,
  ErrorCodes as InternalErrorCodes,
  ExtensionContext as InternalExtensionsContext,
  ExtensionMode as InternalExtensionsMode,
  FieldAggregationType as InternalFieldAggregationType,
  FieldRoleType as InternalFieldRoleType,
  FilterType as InternalFilterType,
  FilterUpdateType as InternalFilterUpdateType,
  HierarchicalLevelSelectionState as InternalHierarchicalLevelSelectionState,
  MarkType as InternalMarkType,
  SheetType as InternalSheetType,
} from '@tableau/api-internal-contract-js';
import { EnumConverter } from '../Utils/EnumConverter';

/* tslint:disable:typedef - Disable this to make declaring these classes a bit easier */
/**
 * Maps enums used by the internal-api-contract to the enums used
 * in the external-api-contract, which developers code against.
 */
export class InternalToExternalEnumMappings {
  public static extensionContext = new EnumConverter<InternalExtensionsContext, ExternalExtensionsContext>({
    [InternalExtensionsContext.Desktop]: ExternalExtensionsContext.Desktop,
    [InternalExtensionsContext.Server]: ExternalExtensionsContext.Server,
  });

  public static extensionMode = new EnumConverter<InternalExtensionsMode, ExternalExtensionsMode>({
    [InternalExtensionsMode.Authoring]: ExternalExtensionsMode.Authoring,
    [InternalExtensionsMode.Viewing]: ExternalExtensionsMode.Viewing,
  });

  public static columnType = new EnumConverter<InternalColumnType, ExternalColumnType>({
    [InternalColumnType.Continuous]: ExternalColumnType.Continuous,
    [InternalColumnType.Discrete]: ExternalColumnType.Discrete,
  });

  public static fieldAggregationType = new EnumConverter<InternalFieldAggregationType, ExternalFieldAggregationType>({
    [InternalFieldAggregationType.Attr]: ExternalFieldAggregationType.Attr,
    [InternalFieldAggregationType.Avg]: ExternalFieldAggregationType.Avg,
    [InternalFieldAggregationType.Collect]: ExternalFieldAggregationType.Collect,
    [InternalFieldAggregationType.Count]: ExternalFieldAggregationType.Count,
    [InternalFieldAggregationType.Countd]: ExternalFieldAggregationType.Countd,
    [InternalFieldAggregationType.Day]: ExternalFieldAggregationType.Day,
    [InternalFieldAggregationType.End]: ExternalFieldAggregationType.End,
    [InternalFieldAggregationType.Hour]: ExternalFieldAggregationType.Hour,
    [InternalFieldAggregationType.InOut]: ExternalFieldAggregationType.InOut,
    [InternalFieldAggregationType.Kurtosis]: ExternalFieldAggregationType.Kurtosis,
    [InternalFieldAggregationType.Max]: ExternalFieldAggregationType.Max,
    [InternalFieldAggregationType.Mdy]: ExternalFieldAggregationType.Mdy,
    [InternalFieldAggregationType.Median]: ExternalFieldAggregationType.Median,
    [InternalFieldAggregationType.Min]: ExternalFieldAggregationType.Min,
    [InternalFieldAggregationType.Minute]: ExternalFieldAggregationType.Minute,
    [InternalFieldAggregationType.MonthYear]: ExternalFieldAggregationType.MonthYear,
    [InternalFieldAggregationType.None]: ExternalFieldAggregationType.None,
    [InternalFieldAggregationType.Qtr]: ExternalFieldAggregationType.Qtr,
    [InternalFieldAggregationType.Quart1]: ExternalFieldAggregationType.Quart1,
    [InternalFieldAggregationType.Quart3]: ExternalFieldAggregationType.Quart3,
    [InternalFieldAggregationType.Second]: ExternalFieldAggregationType.Second,
    [InternalFieldAggregationType.Skewness]: ExternalFieldAggregationType.Skewness,
    [InternalFieldAggregationType.Stdev]: ExternalFieldAggregationType.Stdev,
    [InternalFieldAggregationType.Stdevp]: ExternalFieldAggregationType.Stdevp,
    [InternalFieldAggregationType.Sum]: ExternalFieldAggregationType.Sum,
    [InternalFieldAggregationType.TruncDay]: ExternalFieldAggregationType.TruncDay,
    [InternalFieldAggregationType.TruncHour]: ExternalFieldAggregationType.TruncHour,
    [InternalFieldAggregationType.TruncMinute]: ExternalFieldAggregationType.TruncMinute,
    [InternalFieldAggregationType.TruncMonth]: ExternalFieldAggregationType.TruncMonth,
    [InternalFieldAggregationType.TruncQtr]: ExternalFieldAggregationType.TruncQtr,
    [InternalFieldAggregationType.TruncSecond]: ExternalFieldAggregationType.TruncSecond,
    [InternalFieldAggregationType.TruncWeek]: ExternalFieldAggregationType.TruncWeek,
    [InternalFieldAggregationType.TruncYear]: ExternalFieldAggregationType.TruncYear,
    [InternalFieldAggregationType.User]: ExternalFieldAggregationType.User,
    [InternalFieldAggregationType.Var]: ExternalFieldAggregationType.Var,
    [InternalFieldAggregationType.Varp]: ExternalFieldAggregationType.Varp,
    [InternalFieldAggregationType.Week]: ExternalFieldAggregationType.Week,
    [InternalFieldAggregationType.Weekday]: ExternalFieldAggregationType.Weekday,
    [InternalFieldAggregationType.Year]: ExternalFieldAggregationType.Year,
  });

  public static fieldRoleType = new EnumConverter<InternalFieldRoleType, ExternalFieldRoleType>({
    [InternalFieldRoleType.Dimension]: ExternalFieldRoleType.Dimension,
    [InternalFieldRoleType.Measure]: ExternalFieldRoleType.Measure,
    [InternalFieldRoleType.Unknown]: ExternalFieldRoleType.Unknown,
  });

  public static sheetType = new EnumConverter<InternalSheetType, ExternalSheetType>({
    [InternalSheetType.Dashboard]: ExternalSheetType.Dashboard,
    [InternalSheetType.Story]: ExternalSheetType.Story,
    [InternalSheetType.Worksheet]: ExternalSheetType.Worksheet,
  });

  public static dashboardObjectType = new EnumConverter<InternalDashboardObjectType, ExternalDashboardObjectType>({
    [InternalDashboardObjectType.Extension]: ExternalDashboardObjectType.Extension,
    [InternalDashboardObjectType.Blank]: ExternalDashboardObjectType.Blank,
    [InternalDashboardObjectType.Image]: ExternalDashboardObjectType.Image,
    [InternalDashboardObjectType.Legend]: ExternalDashboardObjectType.Legend,
    [InternalDashboardObjectType.PageFilter]: ExternalDashboardObjectType.PageFilter,
    [InternalDashboardObjectType.ParameterControl]: ExternalDashboardObjectType.ParameterControl,
    [InternalDashboardObjectType.QuickFilter]: ExternalDashboardObjectType.QuickFilter,
    [InternalDashboardObjectType.Text]: ExternalDashboardObjectType.Text,
    [InternalDashboardObjectType.Title]: ExternalDashboardObjectType.Title,
    [InternalDashboardObjectType.WebPage]: ExternalDashboardObjectType.WebPage,
    [InternalDashboardObjectType.Worksheet]: ExternalDashboardObjectType.Worksheet,
  });

  public static dataType = new EnumConverter<InternalDataType, ExternalDataType>({
    [InternalDataType.Bool]: ExternalDataType.Bool,
    [InternalDataType.Date]: ExternalDataType.Date,
    [InternalDataType.DateTime]: ExternalDataType.DateTime,
    [InternalDataType.Float]: ExternalDataType.Float,
    [InternalDataType.Int]: ExternalDataType.Int,
    [InternalDataType.String]: ExternalDataType.String,
  });

  public static filterUpdateType = new EnumConverter<InternalFilterUpdateType, ExternalFilterUpdateType>({
    [InternalFilterUpdateType.Add]: ExternalFilterUpdateType.Add,
    [InternalFilterUpdateType.All]: ExternalFilterUpdateType.All,
    [InternalFilterUpdateType.Remove]: ExternalFilterUpdateType.Remove,
    [InternalFilterUpdateType.Replace]: ExternalFilterUpdateType.Replace,
  });

  public static allowableValues = new EnumConverter<InternalDomainRestrictionType, ExternalParameterValueType>({
    [InternalDomainRestrictionType.All]: ExternalParameterValueType.All,
    [InternalDomainRestrictionType.List]: ExternalParameterValueType.List,
    [InternalDomainRestrictionType.Range]: ExternalParameterValueType.Range,
  });

  public static dateStepPeriod = new EnumConverter<InternalDateStepPeriod, ExternalDatePeriod>({
    [InternalDateStepPeriod.Years]: ExternalDatePeriod.Years,
    [InternalDateStepPeriod.Quarters]: ExternalDatePeriod.Quarters,
    [InternalDateStepPeriod.Months]: ExternalDatePeriod.Months,
    [InternalDateStepPeriod.Weeks]: ExternalDatePeriod.Weeks,
    [InternalDateStepPeriod.Days]: ExternalDatePeriod.Days,
    [InternalDateStepPeriod.Hours]: ExternalDatePeriod.Hours,
    [InternalDateStepPeriod.Minutes]: ExternalDatePeriod.Minutes,
    [InternalDateStepPeriod.Seconds]: ExternalDatePeriod.Seconds,
  });

  public static dateRangeType = new EnumConverter<InternalDateRangeType, ExternalDateRangeType>({
    [InternalDateRangeType.Current]: ExternalDateRangeType.Current,
    [InternalDateRangeType.Last]: ExternalDateRangeType.Last,
    [InternalDateRangeType.LastN]: ExternalDateRangeType.LastN,
    [InternalDateRangeType.Next]: ExternalDateRangeType.Next,
    [InternalDateRangeType.NextN]: ExternalDateRangeType.NextN,
    [InternalDateRangeType.ToDate]: ExternalDateRangeType.ToDate,
  });

  public static errorCode = new EnumConverter<InternalErrorCodes, ExternalErrorCodes>(
    {
      [InternalErrorCodes.INITIALIZATION_ERROR]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.INTERNAL_ERROR]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.MISSING_ENUM_MAPPING]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.MISSING_PARAMETER]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.PERMISSION_DENIED]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.PRES_MODEL_PARSING_ERROR]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.UNKNOWN_VERB_ID]: ExternalErrorCodes.InternalError,
      [InternalErrorCodes.VERSION_NOT_CONFIGURED]: ExternalErrorCodes.APINotInitialized,
      [InternalErrorCodes.VISIBILITY_ERROR]: ExternalErrorCodes.VisibilityError,
    },
    ExternalErrorCodes.InternalError,
  );

  public static filterType = new EnumConverter<InternalFilterType, ExternalFilterType>({
    [InternalFilterType.Categorical]: ExternalFilterType.Categorical,
    [InternalFilterType.Range]: ExternalFilterType.Range,
    [InternalFilterType.RelativeDate]: ExternalFilterType.RelativeDate,
    [InternalFilterType.Hierarchical]: ExternalFilterType.Hierarchical,
  });

  public static classNameKey = new EnumConverter<InternalClassNameKey, ExternalClassNameKey>({
    [InternalClassNameKey.DashboardTitle]: ExternalClassNameKey.DashboardTitle,
    [InternalClassNameKey.StoryTitle]: ExternalClassNameKey.StoryTitle,
    [InternalClassNameKey.Tooltip]: ExternalClassNameKey.Tooltip,
    [InternalClassNameKey.Worksheet]: ExternalClassNameKey.Worksheet,
    [InternalClassNameKey.WorksheetTitle]: ExternalClassNameKey.WorksheetTitle,
    // Temporary until we fix 1317266 - Platform hasn't been updated currently because near branch is closed
    ['dashboard-title']: ExternalClassNameKey.DashboardTitle,
    ['story-title']: ExternalClassNameKey.StoryTitle,
    ['tooltip']: ExternalClassNameKey.Tooltip,
    ['worksheet']: ExternalClassNameKey.Worksheet,
    ['worksheet-title']: ExternalClassNameKey.WorksheetTitle,
  });

  public static hierarchicalLevelSelectionState = new EnumConverter<
    InternalHierarchicalLevelSelectionState,
    ExternalHierarchicalLevelSelectionState
  >({
    [InternalHierarchicalLevelSelectionState.AllSelected]: ExternalHierarchicalLevelSelectionState.AllSelected,
    [InternalHierarchicalLevelSelectionState.NoneSelected]: ExternalHierarchicalLevelSelectionState.NoneSelected,
    [InternalHierarchicalLevelSelectionState.SomeSelected]: ExternalHierarchicalLevelSelectionState.SomeSelected,
    [InternalHierarchicalLevelSelectionState.UnknownSelected]: ExternalHierarchicalLevelSelectionState.UnknownSelected,
  });

  public static annotationType = new EnumConverter<InternalAnnotationType, ExternalAnnotationType>({
    [InternalAnnotationType.Area]: ExternalAnnotationType.Area,
    [InternalAnnotationType.Mark]: ExternalAnnotationType.Mark,
    [InternalAnnotationType.Point]: ExternalAnnotationType.Point,
  });

  public static markType = new EnumConverter<InternalMarkType, ExternalMarkType>({
    [InternalMarkType.Area]: ExternalMarkType.Area,
    [InternalMarkType.Bar]: ExternalMarkType.Bar,
    [InternalMarkType.Circle]: ExternalMarkType.Circle,
    [InternalMarkType.GanttBar]: ExternalMarkType.GanttBar,
    [InternalMarkType.Heatmap]: ExternalMarkType.Heatmap,
    [InternalMarkType.Line]: ExternalMarkType.Line,
    [InternalMarkType.Map]: ExternalMarkType.Map,
    [InternalMarkType.Pie]: ExternalMarkType.Pie,
    [InternalMarkType.Polygon]: ExternalMarkType.Polygon,
    [InternalMarkType.Shape]: ExternalMarkType.Shape,
    [InternalMarkType.Square]: ExternalMarkType.Square,
    [InternalMarkType.Text]: ExternalMarkType.Text,
    [InternalMarkType.VizExtension]: ExternalMarkType.VizExtension,
  });
}
/* tslint:enable:typedef */
