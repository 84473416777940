import { TableauWebComponent } from './Components/TableauWebComponent';
import { PromiseQueue } from './Utils/PromiseQueue';

export class WebComponentManager {
  // This contains a static counter & a map that keeps track of the webComponents embedded.
  private static embeddingIndex = 0;
  private static webComponents: Map<number, TableauWebComponent> = new Map<number, TableauWebComponent>();
  private static queue = new PromiseQueue();
  private static signedInOrigins: Set<string> = new Set();

  public static authRequired(src: string, forceTokenSync: boolean): boolean {
    if (!forceTokenSync) {
      return true;
    }
    try {
      const url = new URL(src);
      if (this.signedInOrigins.has(url.origin)) {
        return false;
      }
    } catch {
      // we reach here if url is invalid but we cannot do much here. Jut continue the normal flow.
      return true;
    }
    return true;
  }

  public static updateSignedInOrigin(src: string, forceTokenSync: boolean) {
    if (!forceTokenSync) {
      return;
    }
    try {
      const url = new URL(src);
      this.signedInOrigins.add(url.origin);
    } catch (e) {
      // not a lot we can do here
      return;
    }
  }

  public static clearOrigin(src: string | null, forceTokenSync: boolean): void {
    if (!forceTokenSync) {
      return;
    }
    if (!src) {
      return;
    }
    try {
      const url = new URL(src);
      this.signedInOrigins.delete(url.origin);
    } catch (e) {
      //not a lot we can do here
      return;
    }
  }

  public static registerWebComponent(webComponent: TableauWebComponent): number {
    const assignedId = this.embeddingIndex;
    this.webComponents.set(assignedId, webComponent);
    this.embeddingIndex++;
    return assignedId;
  }

  public static unregisterWebComponent(embeddingIndex: number): void {
    this.webComponents.delete(embeddingIndex);
  }

  // Methods below only used for testing
  public static getWebComponents(): Map<number, TableauWebComponent> {
    const webComponentsCloned = new Map<number, TableauWebComponent>();
    // a shallow copy is good enough
    this.webComponents.forEach((webComponent: TableauWebComponent, embeddingIndex: number) => {
      webComponentsCloned.set(embeddingIndex, webComponent);
    });
    return webComponentsCloned;
  }

  public static synchronizeRender(func: () => Promise<void>): Promise<void> {
    const promise = this.queue.add(func);
    this.queue.poll();
    return promise;
  }

  public static clear(): void {
    this.webComponents.clear();
  }
}
