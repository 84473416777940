import { ApiServiceRegistry } from '@tableau/api-shared-js';
import { EmbeddingAbstractQuery, EmbeddingVizStateWithDataModel } from '@tableau/api-external-contract-js';
import { EmbeddingVizApiService } from '../Services/EmbeddingVizApiService';
import { EmbeddingServiceNames } from '../Services/EmbeddingServiceNames';

export class AuthoringWorksheetImpl {
  constructor(private _registryId: number) {}

  public getAbstractQueryWithDataModel(): Promise<EmbeddingAbstractQuery> {
    const service = ApiServiceRegistry.get(this._registryId).getService<EmbeddingVizApiService>(
      EmbeddingServiceNames.EmbeddingVizApiService,
    );
    return service.getEmbeddingAbstractQueryAsync();
  }

  public getVizStateWithDataModel(): Promise<EmbeddingVizStateWithDataModel> {
    const service = ApiServiceRegistry.get(this._registryId).getService<EmbeddingVizApiService>(
      EmbeddingServiceNames.EmbeddingVizApiService,
    );
    return service.getEmbeddingVizStateWithDataModelAsync();
  }

  public setVizStateWithDataModel(vizStateWithDataModel: EmbeddingVizStateWithDataModel): Promise<void> {
    const service = ApiServiceRegistry.get(this._registryId).getService<EmbeddingVizApiService>(
      EmbeddingServiceNames.EmbeddingVizApiService,
    );
    return service.setEmbeddingVizStateWithDataModelAsync(vizStateWithDataModel);
  }
}
