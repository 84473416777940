export type PromiseFactory<T> = () => PromiseLike<T>;

/**
 * A custom promise queue that serializes execution of multiple promises.
 *
 * @export
 * @class PromiseQueue
 */
export class PromiseQueue {
  private queue: (() => void)[];
  private pending: number;
  private concurrency: number;

  /**
   * Creates an instance of PromiseQueue.
   */
  constructor() {
    this.queue = [];
    this.pending = 0;
    this.concurrency = 1;
  }

  /**
   * Add a function that returns a promise to the queue.
   *
   * @template T
   * @param {PromiseFactory<T>} func
   * @return {*}  {Promise<T>}
   * @memberof PromiseQueue
   */
  public add<T>(func: PromiseFactory<T>): Promise<T> {
    const promise = new Promise<T>((resolve, reject) => {
      this.queue.push(async () => {
        try {
          const result = await func();
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          this.pending--;
          this.poll();
        }
      });
    });
    return promise;
  }

  public poll(): void {
    if (this.pending < this.concurrency && this.queue.length > 0) {
      const queueJob = this.queue.shift();
      this.pending++;
      queueJob!();
    }
  }
  public size(): number {
    return this.queue.length;
  }
}
