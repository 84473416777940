import * as Contract from '@tableau/api-external-contract-js';
import { TableauError } from '@tableau/api-shared-js';
import { EmbeddingWorkbookImpl } from '../Impl/EmbeddingWorkbookImpl';
import { AuthoringWorksheetImpl } from '../Impl/AuthoringWorksheetImpl';
import { AuthoringWorksheet } from './AuthoringWorksheet';

export class AuthoringWorkbook implements Contract.EmbeddingAuthoringWorkbook {
  public constructor(private _workbookImpl: EmbeddingWorkbookImpl, private _registryId: number) {}

  public get activeSheet(): AuthoringWorksheet {
    if (this._workbookImpl.activeSheet.sheetType === Contract.SheetType.Worksheet) {
      const authoringWorksheetImpl = new AuthoringWorksheetImpl(this._registryId);
      return new AuthoringWorksheet(authoringWorksheetImpl);
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.InvalidSheetType, 'Worksheet type not yet supported in Authoring');
  }
}
