import {
  DateRangeType as ExternalDateRangeType,
  DialogStyle as ExternalDialogStyle,
  FilterDomainType as ExternalDomainType,
  FilterNullOption as ExternalNullOption,
  FilterUpdateType as ExternalFilterUpdateType,
  IncludeDataValuesOption,
  PeriodType,
  ReplaySpeedType,
  SheetType as ExternalSheetType,
  ZoneVisibilityType,
} from '@tableau/api-external-contract-js';
import {
  ApiShowDataTableFormat,
  DateRangeType as InternalDateRangeType,
  DateStepPeriod,
  DialogStyle as InternalDialogStyle,
  FilterDomainType as InternalDomainType,
  FilterNullOption as InternalNullOption,
  FilterUpdateType as InternalFilterUpdateType,
  SheetType as InternalSheetType,
} from '@tableau/api-internal-contract-js';
import { EnumConverter } from '../Utils/EnumConverter';

/* tslint:disable:typedef - Disable this to make declaring these classes a bit easier */
/**
 * Maps enums used by the external-api-contract to the enums used
 * in the internal-api-contract, which developers code against.
 */
export class ExternalToInternalEnumMappings {
  public static filterDomainType = new EnumConverter<ExternalDomainType, InternalDomainType>({
    [ExternalDomainType.Relevant]: InternalDomainType.Relevant,
    [ExternalDomainType.Database]: InternalDomainType.Database,
  });

  public static nullOptions = new EnumConverter<ExternalNullOption, InternalNullOption>({
    [ExternalNullOption.AllValues]: InternalNullOption.AllValues,
    [ExternalNullOption.NonNullValues]: InternalNullOption.NonNullValues,
    [ExternalNullOption.NullValues]: InternalNullOption.NullValues,
  });

  public static filterUpdateType = new EnumConverter<ExternalFilterUpdateType, InternalFilterUpdateType>({
    [ExternalFilterUpdateType.Add]: InternalFilterUpdateType.Add,
    [ExternalFilterUpdateType.All]: InternalFilterUpdateType.All,
    [ExternalFilterUpdateType.Remove]: InternalFilterUpdateType.Remove,
    [ExternalFilterUpdateType.Replace]: InternalFilterUpdateType.Replace,
  });

  public static setVisibilityType = new EnumConverter<ZoneVisibilityType, boolean>({
    [ZoneVisibilityType.Show]: true,
    [ZoneVisibilityType.Hide]: false,
  });

  public static setReplaySpeedType = new EnumConverter<ReplaySpeedType, number>({
    // these numbers are duration multipliers
    // 2.0, for example, doubles the duration of the replay, slowing it down to 0.5x speed
    [ReplaySpeedType.Slow]: 2.0,
    [ReplaySpeedType.Normal]: 1.0,
    [ReplaySpeedType.Fast]: 0.5,
  });

  public static sheetType = new EnumConverter<ExternalSheetType, InternalSheetType>({
    [ExternalSheetType.Dashboard]: InternalSheetType.Dashboard,
    [ExternalSheetType.Worksheet]: InternalSheetType.Worksheet,
    [ExternalSheetType.Story]: InternalSheetType.Story,
  });

  public static showDataTableFormatType = new EnumConverter<IncludeDataValuesOption, ApiShowDataTableFormat>({
    [IncludeDataValuesOption.AllValues]: ApiShowDataTableFormat.NativeAndFormattedValues,
    [IncludeDataValuesOption.OnlyNativeValues]: ApiShowDataTableFormat.NativeValuesOnly,
    [IncludeDataValuesOption.OnlyFormattedValues]: ApiShowDataTableFormat.FormattedValuesOnly,
  });

  public static periodType = new EnumConverter<PeriodType, DateStepPeriod>({
    [PeriodType.Years]: DateStepPeriod.Years,
    [PeriodType.Quarters]: DateStepPeriod.Quarters,
    [PeriodType.Months]: DateStepPeriod.Months,
    [PeriodType.Weeks]: DateStepPeriod.Weeks,
    [PeriodType.Days]: DateStepPeriod.Days,
    [PeriodType.Hours]: DateStepPeriod.Hours,
    [PeriodType.Minutes]: DateStepPeriod.Minutes,
    [PeriodType.Seconds]: DateStepPeriod.Seconds,
  });

  public static dateRangeType = new EnumConverter<ExternalDateRangeType, InternalDateRangeType>({
    [ExternalDateRangeType.Last]: InternalDateRangeType.Last,
    [ExternalDateRangeType.LastN]: InternalDateRangeType.LastN,
    [ExternalDateRangeType.Next]: InternalDateRangeType.Next,
    [ExternalDateRangeType.NextN]: InternalDateRangeType.NextN,
    [ExternalDateRangeType.Current]: InternalDateRangeType.Current,
    [ExternalDateRangeType.ToDate]: InternalDateRangeType.ToDate,
  });

  public static dialogStyles = new EnumConverter<ExternalDialogStyle, InternalDialogStyle>({
    [ExternalDialogStyle.Window]: InternalDialogStyle.Window,
    [ExternalDialogStyle.Modal]: InternalDialogStyle.Modal,
    [ExternalDialogStyle.Modeless]: InternalDialogStyle.Modeless,
  });
}
/* tslint:enable:typedef */
