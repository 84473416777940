/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

import { EmbeddingErrorCodes, SharedErrorCodes } from '@tableau/api-external-contract-js';
import { ApiVersion } from '@tableau/api-shared-js';
import { TableauAskData } from './EmbeddingApi/Components/TableauAskData';
import { TableauAuthoringViz } from './EmbeddingApi/Components/TableauAuthoringViz';
import { TableauPulse } from './EmbeddingApi/Components/TableauPulse';
import { TableauViz } from './EmbeddingApi/Components/TableauViz';

declare let VERSION_IS_ALPHA: boolean;
const isAlpha: boolean = typeof VERSION_IS_ALPHA !== 'undefined' ? VERSION_IS_ALPHA : false;

declare let API_VERSION_NUMBER: string;
ApiVersion.SetVersionNumber(typeof API_VERSION_NUMBER !== 'undefined' ? API_VERSION_NUMBER : '0.0.0', isAlpha);

window.customElements.define('tableau-viz', TableauViz);
window.customElements.define('tableau-authoring-viz', TableauAuthoringViz);
window.customElements.define('tableau-ask-data', TableauAskData);
window.customElements.define('tableau-pulse', TableauPulse);

const ErrorCodes = { ...EmbeddingErrorCodes, ...SharedErrorCodes };
// Export Enums & Interfaces
export {
  AnalyticsObjectType,
  Annotation,
  AnnotationType,
  ApiMenuType,
  CategoricalDomain,
  CategoricalFilter,
  ClassNameKey,
  Column,
  ColumnType,
  ConnectionSummary,
  ContextMenuOptions,
  CrosstabFileFormat,
  EmbeddingCustomMarkContextMenuEvent as CustomMarkContextMenuEvent,
  CustomView,
  CustomViewEvent,
  EmbeddingDashboard as Dashboard,
  DashboardLayoutChange,
  EmbeddingDashboardObject as DashboardObject,
  DashboardObjectType,
  DataSource,
  DataSourceUnderlyingDataOptions,
  DataTable,
  DataTableReader,
  DataType,
  DataValue,
  DateRangeType,
  DeviceType,
  EncodingType,
  ExportDataOptions,
  ExportPDFOptions,
  Field,
  FieldAggregationType,
  FieldRoleType,
  FileFormats,
  Filter,
  FilterChangedEvent,
  FilterDomainType,
  FilterNullOption,
  FilterOptions,
  FilterType,
  FilterUpdateType,
  FirstVizSizeKnownEvent,
  GetSummaryDataOptions,
  GetUnderlyingDataOptions,
  HierarchicalFilter,
  HierarchicalFilterDataValue,
  HierarchicalLevelDetail,
  HierarchicalLevelSelectionState,
  HierarchicalLevels,
  IncludeDataValuesOption,
  LogicalTable,
  MarkInfo,
  MarkType,
  MarksCollection,
  MarksSelectedEvent,
  EmbeddingParameter as Parameter,
  EmbeddingParameterChangedEvent as ParameterChangedEvent,
  ParameterDomainRestriction,
  ParameterValueType,
  PeriodType,
  Point,
  PrintOrientation,
  PrintPageSize,
  PrintScaling,
  QuickTableCalcType,
  RangeDomain,
  RangeFilter,
  RangeFilterOptions,
  RangeValue,
  RelativeDateFilter,
  RelativeDateFilterOptions,
  SelectionCriteria,
  SelectionUpdateType,
  EmbeddingSheet as Sheet,
  SheetInfo,
  SheetSize,
  SheetSizeBehavior,
  SheetType,
  Size,
  SortDirection,
  Story,
  StoryPoint,
  StoryPointInfo,
  StoryPointSwitchedEvent,
  TabSwitchedEvent,
  TableauDialogType,
  TableauEmbeddingError as TableauError,
  EmbeddingTableauEventType as TableauEventType,
  Toolbar,
  EmbeddingToolbarStateChangedEvent as ToolbarStateChangedEvent,
  TrendLineModelType,
  UrlActionEvent,
  VizLoadErrorEvent,
  VizSize,
  EmbeddingWorkbook as Workbook,
  WorkbookPublishedAsEvent,
  EmbeddingWorksheet as Worksheet,
} from '@tableau/api-external-contract-js';
export { TableauAskData } from './EmbeddingApi/Components/TableauAskData';
export { TableauAuthoringViz } from './EmbeddingApi/Components/TableauAuthoringViz';
export { TableauPulse } from './EmbeddingApi/Components/TableauPulse';
export { TableauViz } from './EmbeddingApi/Components/TableauViz';
export { ErrorCodes };
