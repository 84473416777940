import { AskDataSettings, CustomParameter, PulseSettings, VizAuthoringSettings, VizSettings } from '@tableau/api-external-contract-js';
import { AskDataOptionNames, PulseOptionNames, VizOptionNames } from '@tableau/api-internal-contract-js';
export type ParametersMap = Map<VizOptionNames | AskDataOptionNames | PulseOptionNames, string>;

export const SANITIZED_VALUES = {
  YES_VALUE: 'y',
  NO_VALUE: 'n',
} as const;

const supportedProtocols = new Set<string>(['https:', 'http:']);

export abstract class EmbeddingUrlBuilder {
  private _token?: string;
  protected _url: URL;
  protected _optionNames: typeof VizOptionNames | typeof AskDataOptionNames | typeof PulseOptionNames;
  protected abstract sanitizeParameterValue(parameterName: string, value: unknown): string;

  public build(): URL {
    return this._url;
  }

  /**
   * Appends the non-empty parameters to the URL, preserving parameters that already exist.
   * @param defaultParameters The map of key/value pairs to append to the search params.
   * @returns This object, so you can chain calls.
   */
  public appendDefaultParameters(defaultParameters: ParametersMap): this {
    for (const key of defaultParameters.keys()) {
      const value: string | undefined = defaultParameters.get(key);

      // don't overwrite any values already written, and don't add empty default values
      if (value && !this._url.searchParams.has(key)) {
        this._url.searchParams.append(key, value);
      }
    }

    return this;
  }

  /**
   * Appends the user-supplied options to the search params. Only known options will be passed
   * through. All unknown options are ignored.
   * @param options The options to set on the search params.
   * @returns This object, so you can chain calls.
   */
  public appendUserOptions(options: AskDataSettings | VizSettings | VizAuthoringSettings | PulseSettings): this {
    for (const key of Object.keys(options)) {
      // ignore null/unset values
      if (options[key] === null || options[key] === undefined) {
        continue;
      }

      const parameterName = this._optionNames[key];

      // only accept known parameter names
      if (!parameterName) {
        continue;
      }

      const cleanedValue = this.sanitizeParameterValue(parameterName, options[key]);
      this._url.searchParams.append(parameterName, cleanedValue);
    }

    return this;
  }

  public appendCustomParams(customParams: CustomParameter[]): this {
    for (const customParam of customParams) {
      this._url.searchParams.set(customParam.name, customParam.value);
    }
    return this;
  }

  public setToken(token?: string): this {
    if (this._token) {
      throw new Error(`The token has already been set to ${this._token}`);
    }

    if (!token) {
      return this;
    }

    this._token = token;

    // the target is everything after the origin
    const target = this._url.toString().substring(this._url.origin.length);

    // change the URL path to include the ticket entry point in vizportal
    this._url = new URL(`${this._url.origin}/vizportal/api/web/v1/auth/embed/target`);
    this._url.searchParams.append('token', token);
    this._url.searchParams.append('target', target);

    return this;
  }

  protected sanitizeValue(v: unknown) {
    const valueAsString = String(v);
    switch (valueAsString) {
      case 'true':
        return SANITIZED_VALUES.YES_VALUE;
      case 'false':
        return SANITIZED_VALUES.NO_VALUE;
      default:
        return valueAsString;
    }
  }
}

export function validateUrl(url: URL): void {
  validateProtocolInUrl(url);
}

function validateProtocolInUrl(url: URL): void {
  const protocol = url.protocol;
  if (!supportedProtocols.has(protocol)) {
    throw new Error(`Invalid protocol in URL '${url}'. The protocols supported are ${Array.from(supportedProtocols).join(', ')}.`);
  }
}

export function getSiteIdForPulse(url: URL | string): string {
  const pathname = canonicalizeVizPortalRoutingHashes(new URL(url.toString())).pathname;
  const parts: string[] = pathname.split('/').filter((x) => x);
  if (parts.length === 0) {
    return '';
  }
  //e.g when url is: http://www.example.com/site/queryvdsintegration/pulse/metrics/123
  if (parts[0] === 'site') {
    return parts[1];
  }
  // e.g when url is: http://www.example.com/pulse/site/queryvdsintegration/metrics/123
  if (parts[0] === 'pulse' && parts[1] === 'site') {
    return parts[2];
  }
  return '';
}

export function getSiteId(url: URL | string): string {
  const pathname = canonicalizeVizPortalRoutingHashes(new URL(url.toString())).pathname;
  const parts: string[] = pathname.split('/').filter((x) => x);
  if (parts.length === 0) {
    return '';
  }

  // check if the site root is in the t/siteName form
  // If a siteName is not present in the path, return an empty string to represent the default site.
  if (parts[0] !== 't') {
    return '';
  }

  if (parts.length < 2) {
    return '';
  }

  return parts[1];
}

/**
 * This canonicalizes any URL that contains '/#/site' or '/#/'.
 * Examples:
 * 'https://tableau.com/#/site/alpodev/views/Workbook/Sheet' would return 'https://tableau.com/t/alpodev/views/Workbook/Sheet';
 * 'https://tableau.com/#/views/Workbook/Sheet' would return 'https://tableau.com/views/Workbook/Sheet'.
 */
export function canonicalizeVizPortalRoutingHashes(url: URL): URL {
  let urlStr = url.toString();
  urlStr = urlStr.replace('/#/site/', '/t/').replace('/#/', '/');
  return new URL(urlStr);
}
