import * as Contract from '@tableau/api-external-contract-js';
import { EmbeddingErrorCodes } from '@tableau/api-external-contract-js';

export class VizLoadErrorEvent implements Contract.VizLoadErrorEvent {
  private _errorCode: EmbeddingErrorCodes;
  private _message: string;

  public get message(): string {
    return this._message;
  }

  public get errorCode(): EmbeddingErrorCodes {
    return this._errorCode;
  }

  public constructor(errorCode: EmbeddingErrorCodes, message: string) {
    this._errorCode = errorCode;
    this._message = message;
  }
}
