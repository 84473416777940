/**
 * This is your main. This is where you re-export everything you want to be publicly available.
 *
 * The build enforces that the file has the same name as the global variable that is exported.
 */

// The following polyfills are needed for IE11
import 'core-js/es/number/is-integer';
import 'core-js/es/number/is-nan';
import 'core-js/es/object/assign';
// Export the version of the internal contract to be available at runtime
//  This file is generated by yarn publish:bump via scripts/publish-check.ts
//  updateInternalContractRuntimeVersion in the monorepo root.
import * as internalContractVersion from '../internal-contract-version.json';
export const INTERNAL_CONTRACT_VERSION = internalContractVersion;

export * from './contract/DataTypeConverter';
export * from './contract/Enums';
export * from './contract/Models';
export * from './contract/Notifications';
export * from './contract/Parameters';
export * from './contract/Verbs';
export * from './interface/AskDataInterface';
export * from './interface/InitializationOptions';
export * from './interface/InternalApiDispatcher';
export * from './interface/PulseInterface';
export * from './interface/VersionNumber';
export * from './interface/VqlInterface';
export * from './messaging/CrossFrameMessenger';
export * from './messaging/interface/MessageDispatcher';
export * from './messaging/interface/MessageListener';
export * from './messaging/interface/MessageTypes';
export * from './messaging/interface/Messenger';
export * from './messaging/interface/PreparedMessage';
export * from './versioning/InternalContractVersionConverter';
export * from './versioning/VersionConverterFactory';
export * from './versioning/external/ExternalContractVersionConverter';
export * from './versioning/external/ExternalIdentityVersionConverter';
export * from './versioning/external/ExternalVersionConverterFactory';

// Export the version number of messaging for consumers to use.
// Be very careful making any updates to this contract which break version compatibility.
export const MESSAGING_VERSION = {
  major: 1,
  minor: 0,
  fix: 0,
};
