import * as Contract from '@tableau/api-external-contract-js';
import {
  DashboardLayoutChange,
  DashboardLayoutChangeDetails,
  ErrorCodes,
  SharedErrorCodes,
  SheetType,
} from '@tableau/api-external-contract-js';
import { DashboardObjectType, DashboardZone, SheetPath, VisualId } from '@tableau/api-internal-contract-js';
import { InternalToExternalEnumMappings } from '../EnumMappings/InternalToExternalEnumMappings';
import { Point } from '../Point';
import { AnimationService } from '../Services/AnimationService';
import { FilterService } from '../Services/FilterService';
import { ApiServiceRegistry, ServiceNames } from '../Services/ServiceRegistry';
import { ZoneService } from '../Services/ZoneService';
import { TableauError } from '../TableauError';
import { ErrorHelpers } from '../Utils/ErrorHelpers';
import { DashboardObjectImpl } from './DashboardObjectImpl';
import { SheetImpl } from './SheetImpl';
import { SheetInfoImpl } from './SheetInfoImpl';
import { StoryPointImpl } from './StoryPointImpl';
import { WorksheetImpl } from './WorksheetImpl';

export class DashboardImpl extends SheetImpl {
  private _worksheetsImpl: Array<WorksheetImpl>;
  private _objects: Array<DashboardObjectImpl>;
  private zoneMap: Map<number, DashboardObjectImpl>;

  public constructor(
    _sheetInfo: SheetInfoImpl,
    private _zones: Array<DashboardZone>,
    private _sheetPath: SheetPath,
    _registryId: number,
    private _parentStoryPointImpl: StoryPointImpl | null,
    private _activeDashboardObjectId: number = 0,
  ) {
    super(_sheetInfo, _registryId);
  }

  public get worksheetsImpl(): Array<WorksheetImpl> {
    return this._worksheetsImpl;
  }

  public get objects(): Array<DashboardObjectImpl> {
    return this._objects;
  }

  public get parentStoryPoint(): StoryPointImpl | null {
    return this._parentStoryPointImpl;
  }

  public get activeDashboardObjectId(): number {
    return this._activeDashboardObjectId;
  }

  public initializeWithPublicInterfaces(): void {
    this._worksheetsImpl = new Array<WorksheetImpl>();
    this._objects = new Array<DashboardObjectImpl>();
    this.zoneMap = new Map<number, DashboardObjectImpl>();

    // Process all the zones which are contained in this dashboard
    for (const zone of this._zones) {
      let worksheetImpl: WorksheetImpl | undefined = undefined;

      const zoneSize: Contract.Size = { width: zone.width, height: zone.height };
      // As the dashboard is active, all other zones in the dashboard are inactive.
      const isActive = false;

      if (zone.zoneType === DashboardObjectType.Worksheet) {
        let worksheetName = '';
        let worksheetUrl = '';
        let isHidden = false;
        if (zone.sheetInfo) {
          // zone.sheetInfo was not initialized prior to internal-contract 1.6.0
          worksheetName = zone.sheetInfo.name;

          // worksheetUrl & isHidden is for Embedding only
          worksheetUrl = zone.sheetInfo.url || '';
          // If there's a url, then it's not hidden
          isHidden = worksheetUrl === '';
        } else {
          worksheetName = zone.name;
        }
        // Indexes, isActive and some more properties in sheetInfoImpl are embedding specific.
        // But we init them for both extensions and embedding as the Models will only use what is relevant.
        const sheetInfoImpl = new SheetInfoImpl(
          worksheetName,
          SheetType.Worksheet,
          zoneSize,
          this._worksheetsImpl.length,
          isActive,
          isHidden,
          worksheetUrl,
        );

        const vizId: VisualId = {
          worksheet: worksheetName,
          dashboard: this._sheetInfoImpl.name,
          storyboard: this._sheetPath.storyboard,
          flipboardZoneID: this._sheetPath.flipboardZoneID,
          storyPointID: this._sheetPath.storyPointID,
        };

        worksheetImpl = new WorksheetImpl(sheetInfoImpl, this._registryId, vizId, this, this._parentStoryPointImpl);
        this._worksheetsImpl.push(worksheetImpl);
      }

      const zonePoint = new Point(zone.x, zone.y);

      const dashboardObjectImpl = new DashboardObjectImpl(
        this,
        InternalToExternalEnumMappings.dashboardObjectType.convert(zone.zoneType),
        zonePoint,
        zoneSize,
        worksheetImpl,
        zone.name,
        zone.isFloating !== undefined ? zone.isFloating : false, // before 1.6.0 we didn't have isFloating, so we assume false
        zone.isVisible !== undefined ? zone.isVisible : true, // before 1.6.0 we didn't have isVisible, so we assume true
        zone.zoneId,
      );

      this._objects.push(dashboardObjectImpl);
      this.zoneMap.set(zone.zoneId, dashboardObjectImpl);
    }
  }

  public setDashboardObjectVisibilityAsync(dashboardObjectVisibilityMap: Contract.DashboardObjectVisibilityMap | object): Promise<void> {
    const zoneService = ApiServiceRegistry.get(this._registryId).getService<ZoneService>(ServiceNames.Zone);

    return zoneService.setVisibilityAsync(/*Dashboard Name*/ this.name, this.zoneMap, dashboardObjectVisibilityMap);
  }

  public getDashboardObjectById(dashboardObjectId: number): DashboardObjectImpl | undefined {
    return this.zoneMap.get(dashboardObjectId);
  }

  public updateZones(newZones: Array<DashboardZone>): DashboardLayoutChangeDetails {
    // getting previous dashboard objects
    const oldDashboardObjects = this._objects;
    const oldZoneMap = this.zoneMap;
    // updating zones, and reinitializing instance variables
    this._zones = newZones;
    this.initializeWithPublicInterfaces();
    // getting new dashboard objects
    const newDashboardObjects = this._objects;
    const newZoneMap = this.zoneMap;
    // initializing map for changes
    const zoneChanges: DashboardLayoutChangeDetails = new Map();

    // comparing old dashboard objects with new ones
    oldDashboardObjects.forEach((oldObject) => {
      const oldId: number = oldObject.id;

      // checking if zone was removed
      if (!newZoneMap.has(oldId)) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.Removed);
        return;
      }

      const newObject = newZoneMap.get(oldId);
      if (oldObject.isFloating !== newObject!.isFloating) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.IsFloatingChanged);
      }

      if (oldObject.isVisible !== newObject!.isVisible) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.IsVisibleChanged);
      }

      if (oldObject.name !== newObject!.name) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.NameChanged);
      }

      if (oldObject.position.x !== newObject!.position.x || oldObject.position.y !== newObject!.position.y) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.PositionChanged);
      }

      if (oldObject.size.width !== newObject!.size.width || oldObject.size.height !== newObject!.size.height) {
        this.addChange(oldId, zoneChanges, DashboardLayoutChange.SizeChanged);
      }
    });

    // Checking for any added zones
    newDashboardObjects.forEach((newObject) => {
      if (!oldZoneMap.has(newObject.id)) {
        this.addChange(newObject.id, zoneChanges, DashboardLayoutChange.Added);
      }
    });

    return zoneChanges;
  }

  private addChange(zoneId: number, zoneChanges: DashboardLayoutChangeDetails, change: DashboardLayoutChange): void {
    if (!zoneChanges.has(zoneId)) {
      zoneChanges.set(zoneId, []);
    }

    zoneChanges.get(zoneId)!.push(change);
  }

  public moveAndResizeDashboardObjectsAsync(
    dashboardObjectPositionAndSizeUpdateArray: Contract.DashboardObjectPositionAndSizeUpdateArray,
  ): Promise<void> {
    const zoneService = ApiServiceRegistry.get(this._registryId).getService<ZoneService>(ServiceNames.Zone);

    return zoneService.moveAndResizeAsync(/*Dashboard Name*/ this.name, this.zoneMap, dashboardObjectPositionAndSizeUpdateArray);
  }

  public replayAnimationAsync(replaySpeed: Contract.ReplaySpeedType): Promise<void> {
    const animationService = ApiServiceRegistry.get(this._registryId).getService<AnimationService>(ServiceNames.Animation);

    return animationService.replayAsync(replaySpeed);
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    this.verifyActiveSheetOrEmbeddedInActiveStoryPoint();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.getDashboardFiltersAsync();
  }

  public applyFilterAsync(
    fieldName: string,
    values: Array<string>,
    updateType: Contract.FilterUpdateType,
    options: Contract.FilterOptions,
  ): Promise<string> {
    ErrorHelpers.verifyEnumValue<Contract.FilterUpdateType>(updateType, Contract.FilterUpdateType, 'FilterUpdateType');
    ErrorHelpers.verifyStringParameter(fieldName, 'fieldName');
    if (!Array.isArray(values)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'values parameter for applyDashboardFilterAsync must be an array');
    }
    this.verifyActiveSheetOrEmbeddedInActiveStoryPoint();

    const service = ApiServiceRegistry.get(this._registryId).getService<FilterService>(ServiceNames.Filter);
    return service.applyDashboardFilterAsync(fieldName, values, updateType, options);
  }

  // @W-12986439: remove once initializeWithPublicInterfaces is moved to the constructor for this class
  // This method only exists since worksheetsImpl can be undefined, but we need the worksheet names in the Export APIs
  public getWorksheetNamesFromZones(): Array<string> {
    const worksheetNames: string[] = [];
    for (const zone of this._zones) {
      if (zone.zoneType !== DashboardObjectType.Worksheet) {
        continue;
      }
      // zone.sheetInfo was not initialized prior to internal-contract 1.6.0
      const worksheetName = zone.sheetInfo ? zone.sheetInfo.name : zone.name;
      worksheetNames.push(worksheetName);
    }

    return worksheetNames;
  }

  private verifyActiveSheetOrEmbeddedInActiveStoryPoint() {
    const isRootAndActiveDashboard = this.active;
    const isWithinActiveStoryPoint = this.parentStoryPoint != null && this.parentStoryPoint.active;
    if (!isRootAndActiveDashboard && !isWithinActiveStoryPoint) {
      throw new TableauError(SharedErrorCodes.NotActiveSheet, 'Operation not allowed on non-active sheet');
    }
  }
}
