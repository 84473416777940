import { ErrorCodes } from '@tableau/api-external-contract-js';
import { TableauError } from '../TableauError';

/**
 * Base interface for an api service
 */
export interface ApiService {
  /**
   * Gets the name for this service.
   */
  readonly serviceName: string;
}

/**
 * Collection of service name which will be registered in the api-shared project
 */
export const enum ServiceNames {
  ClientInfo = 'client-info-service',
  DataSourceService = 'data-source-service',
  GetData = 'get-data-service',
  Filter = 'filter-service',
  Notification = 'notification-service',
  Parameters = 'parameters-service',
  Selection = 'selection-service',
  Annotation = 'annotation-service',
  Zone = 'zone-service',
  Animation = 'animation-service',
  Viz = 'viz-service',
  ExternalContextMenu = 'external-context-menu-service',
  Initialization = 'initialization-service',
  StoryActivation = 'story-activation-service',
  Size = 'size-service',
  Export = 'export-service',
  VisualModel = 'visual-model-service',
}

/**
 * Do some global declarations so we can create a singleton on the window object
 */
declare global {
  interface Window {
    __tableauApiServiceRegistry: { [registryId: number]: ServiceRegistry };
  }
}

window.__tableauApiServiceRegistry = window.__tableauApiServiceRegistry || {};

export interface ServiceRegistry {
  /**
   * Registers a new service into the service registry. Any existing one will
   * be overwritten. the service is registered under service.serviceName
   *
   * @param {ApiService} service The servive to register
   */
  registerService(service: ApiService): void;

  /**
   * Retrieves the given service from the registry. If there is not a
   * service registered under that name, throws and error
   *
   * @template T The type of the service
   * @param {string} serviceName The name of the service.
   * @returns {T} The requested service
   */
  getService<T extends ApiService>(serviceName: string): T;
}

class ServiceRegistryImpl implements ServiceRegistry {
  private _services: { [serviceName: string]: ApiService };

  public constructor() {
    this._services = {};
  }

  public registerService(service: ApiService): void {
    this._services[service.serviceName] = service;
  }

  public getService<T extends ApiService>(serviceName: string): T {
    if (!this._services.hasOwnProperty(serviceName)) {
      throw new TableauError(ErrorCodes.InternalError, `Service not registered: ${serviceName}`);
    }

    return this._services[serviceName] as T;
  }
}

/**
 * Static class used for getting access to the single instance
 * of the ApiServiceRegistry associated with the registryId.
 *
 * Extensions by design don't need to store/access multiple service registries. Their single instance of ServiceRegistry is
 * stored/access with registryId = 0.
 *
 * Whereas Embedding needs to be aware of which ServiceRegistry to use when there are multiple vizzes embedded.
 * An example of how window.__tableauApiServiceRegistry will look when there are two vizzes: { 0 : ServiceRegistry, 1 : ServiceRegistry}
 * where registry ID of 0 & 1 refers to viz's internal identifier (which is generated by VizManager).
 */
export class ApiServiceRegistry {
  /**
   * Gets the singleton instance of the ServiceRegistry
   */
  public static get(registryId: number): ServiceRegistry {
    if (!window.__tableauApiServiceRegistry || !window.__tableauApiServiceRegistry[registryId]) {
      ApiServiceRegistry.setInstance(registryId, new ServiceRegistryImpl());
    }

    if (!window.__tableauApiServiceRegistry[registryId]) {
      throw new TableauError(ErrorCodes.InternalError, 'Service registry failed');
    }

    return window.__tableauApiServiceRegistry[registryId];
  }

  /**
   * Helper method that sets the service registry instance for the corresponding registryId. Can be used by unit tests
   *
   * @param {ServiceRegistry} serviceRegistry The new registry
   */
  public static setInstance(registryId: number, serviceRegistry: ServiceRegistry): void {
    if (!window.__tableauApiServiceRegistry) {
      window.__tableauApiServiceRegistry = {};
    }
    window.__tableauApiServiceRegistry[registryId] = serviceRegistry;
  }

  /**
   * Only used by unit tests
   */
  public static clearRegistry(): void {
    window.__tableauApiServiceRegistry = {};
  }

  // Private to avoid anyone constructing this
  private constructor() {}
}
