import * as Contract from '@tableau/api-external-contract-js';
import { ExecuteParameters, ParameterId, VerbId, VisualId, VisualSpecification } from '@tableau/api-internal-contract-js';
import { Field } from '../../Field';
import { FieldImpl } from '../../Impl/FieldImpl';
import { ServiceNames } from '../ServiceRegistry';
import { VisualModelService } from '../VisualModelService';
import { ServiceImplBase } from './ServiceImplBase';

export class VisualModelServiceImpl extends ServiceImplBase implements VisualModelService {
  public get serviceName(): string {
    return ServiceNames.VisualModel;
  }

  public getVisualSpecificationAsync(visualId: VisualId): Promise<Contract.VisualSpecification> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getVisualSpecificationAsync',
      [ParameterId.WorksheetName]: visualId.worksheet,
    };

    return this.execute(VerbId.GetVisualSpecification, parameters).then<Contract.VisualSpecification>((response) => {
      const result = response.result as VisualSpecification;
      return this.convertVisualSpecification(result);
    });
  }

  private convertVisualSpecification(vs: VisualSpecification) {
    return {
      ...vs,
      rowFields: vs.rowFields.map((f) => new Field(new FieldImpl(f))),
      columnFields: vs.columnFields.map((f) => new Field(new FieldImpl(f))),
      marksSpecifications: vs.marksSpecifications.map((ms) => {
        return {
          ...ms,
          encodings: ms.encodings.map((enc) => {
            return { ...enc, field: new Field(new FieldImpl(enc.field)) };
          }),
        };
      }),
    };
  }
}
