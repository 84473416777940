import { WebComponentLocalSettings } from './WebComponentInterfaces';

/**
 * The names of the string constants you can use as HTML attributes for the <tableau-pulse> web component.
 * @ignore
 */
export enum PulseAttributes {
  DisableExploreFilter = 'disable-explore-filter',
}

export interface PulseSettings extends WebComponentLocalSettings {
  /**
   * Indicates whether the explore filter is hidden or visible.
   * ```
   * <tableau-pulse id="tableauPulse" disable-explore-filter>
   * ```
   */
  disableExploreFilter?: boolean;

  /**
   * The token used for authorization
   *
   * ```
   * <tableau-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-authoring-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-ask-data id="tableauAskData" token="some-token-containing-clientId" />
   * ```
   */
  token?: string;
}

/**
 * The interface for the top-level Pulse object.
 **/
export interface Pulse extends PulseSettings {}
