import {
  EmbeddingVizStateWithDataModel,
  EmbeddingAbstractQuery as ExternalEmbeddingAbstractQuery,
  ErrorCodes,
} from '@tableau/api-external-contract-js';
import {
  ExecuteParameters,
  ParameterId,
  VerbId,
  EmbeddingAbstractQuery as InternalEmbeddingAbstractQuery,
} from '@tableau/api-internal-contract-js';
import { ServiceImplBase, TableauError } from '@tableau/api-shared-js';
import { EmbeddingVizApiService } from '../EmbeddingVizApiService';
import { EmbeddingServiceNames } from '../EmbeddingServiceNames';

export class EmbeddingVizApiServiceImpl extends ServiceImplBase implements EmbeddingVizApiService {
  public get serviceName(): string {
    return EmbeddingServiceNames.EmbeddingVizApiService;
  }

  public getEmbeddingVizStateWithDataModelAsync(): Promise<EmbeddingVizStateWithDataModel> {
    const verb = VerbId.GetVizStateWithDataModel;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getVizStateWithDataModel',
    };

    return this.execute(verb, parameters).then<EmbeddingVizStateWithDataModel>((response) => {
      const serverPm = response.result as { [key: string]: string };
      this.checkForErrorParameter(serverPm);
      let vsdm: EmbeddingVizStateWithDataModel = {
        vizStateWithDataModel: serverPm[ParameterId.VizAPIStateWithDataModel],
      };
      return vsdm;
    });
  }

  private checkForErrorParameter(serverPm: { [key: string]: string }) {
    if (serverPm[ParameterId.VizAPIStateWarningMsg]) {
      throw new TableauError(ErrorCodes.InternalError, serverPm[ParameterId.VizAPIStateWarningMsg]);
    }
  }

  public setEmbeddingVizStateWithDataModelAsync(vizStateWithDataModel: EmbeddingVizStateWithDataModel): Promise<void> {
    const verb = VerbId.SetVizStateWithDataModel;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'setVizStateWithDataModel',
      [ParameterId.VizAPIStateWithDataModel]: vizStateWithDataModel.vizStateWithDataModel,
    };

    return this.execute(verb, parameters).then<void>((response) => {
      if (Object.keys(response.result).length !== 0) {
        const errorMsg = response.result as string;
        throw new TableauError(ErrorCodes.InternalError, errorMsg);
      }
      return;
    });
  }

  public getEmbeddingAbstractQueryAsync(): Promise<ExternalEmbeddingAbstractQuery> {
    const verb = VerbId.GetEmbeddingAbstractQueryWithDataModel;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getEmbeddingAbstractQuery',
    };

    return this.execute(verb, parameters).then<InternalEmbeddingAbstractQuery>((response) => {
      const externalAbstractQuery = response.result as ExternalEmbeddingAbstractQuery;
      return externalAbstractQuery;
    });
  }
}
