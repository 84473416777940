import {
  CustomParameter,
  EmbeddingErrorCodes,
  FilterParameters,
  Toolbar,
  VizAuthoringSettings,
  VizParameter,
  VizSettings,
} from '@tableau/api-external-contract-js';
import { INTERNAL_CONTRACT_VERSION, VizOptionNames } from '@tableau/api-internal-contract-js';
import { ApiVersion, TableauError } from '@tableau/api-shared-js';
import { EmbeddingUrlBuilder, ParametersMap, SANITIZED_VALUES, validateUrl } from './EmbeddingUrlBuilder';
import { VizUrl, VizUrlMode } from './VizUrl';

export enum EmbeddingUrlMode {
  Viewing,
  Authoring,
}

const embeddingUrlModeToVizUrlMode = new Map<EmbeddingUrlMode, VizUrlMode>([
  [EmbeddingUrlMode.Viewing, VizUrlMode.Viewing],
  [EmbeddingUrlMode.Authoring, VizUrlMode.Authoring],
]);

export class EmbeddingVizUrlBuilder extends EmbeddingUrlBuilder {
  public constructor(_url: URL) {
    super();
    this._url = _url;
    this._optionNames = VizOptionNames;
  }

  public appendFilters(filters: FilterParameters[]): EmbeddingVizUrlBuilder {
    for (const filter of filters) {
      this._url.searchParams.append(filter.field, filter.value);
    }

    return this;
  }

  public appendParams(params: VizParameter[]): EmbeddingVizUrlBuilder {
    for (const param of params) {
      this._url.searchParams.append(param.name, param.value);
    }

    return this;
  }

  /**
   * Sanitizes parameter values before they are added to the search params.
   * @param parameterName The name of the parameter. Some parameters require special handling.
   * @param value The raw value of the parameter.
   */
  protected sanitizeParameterValue(parameterName: string, value: unknown): string {
    // Some parameters need their values to be flipped (false in the property is a yes to server)
    let sanitizedValue: string;

    switch (parameterName) {
      case VizOptionNames.hideTabs:
        // ! here to reverse the value hideTabs = true -> tabs:n
        sanitizedValue = this.sanitizeValue(!value);
        break;

      case VizOptionNames.toolbar:
        sanitizedValue = this.sanitizeValue(value === Toolbar.Hidden ? false : value);
        break;

      default:
        sanitizedValue = this.sanitizeValue(value);
        break;
    }

    return sanitizedValue;
  }
}

/**
 * This function should be the only one in api-embedding to contain any knowledge of how to
 * construct a url for vizql including what parameters can be sent and what values they can have or
 * will default to.
 *
 * NOTE-jrockwood-2021-12-02: There is also a {@link VizUrl} class and it might be confusing on the
 * difference between the two.
 *
 * VizUrl - used to parse and construct valid URLs that point to a viz in a particular mode (viewing
 * vs. authoring). It ensures that query parameters are correctly encoded, but it is ignorant of any
 * semantic meaning assigned to query (search) parameters. The plan is to move this into it's own
 * module that will be used in this code and in the VizClient core code.
 *
 * EmbeddingUrl - this class knows how to assemble query parameters specific to the embedding use
 * cases. It uses VizUrl as part of its implementation to ensure that the mode (viewing vs.
 * authoring) is correct.
 */
export function createVizUrl(
  src: string | null,
  mode: EmbeddingUrlMode,
  vizOptions: VizSettings | VizAuthoringSettings,
  embeddingId: number,
  filters: FilterParameters[],
  params: VizParameter[],
  customParams: CustomParameter[],
): URL {
  if (!src) {
    throw new TableauError(EmbeddingErrorCodes.InternalError, 'We should not have attempted to render the component without a src');
  }
  // ensure the URL is in the right mode (authoring/viewing)
  const urlMode = embeddingUrlModeToVizUrlMode.get(mode);
  if (!urlMode) {
    throw new TableauError(EmbeddingErrorCodes.InternalError, `'${mode}' is not yet supported.`);
  }

  // strip params in URL, all custom params should come through 'vizOptions', 'filters' or 'customParams'.
  const srcWithoutQueryParams = src.split('?')[0];

  let url: URL;
  try {
    url = new URL(srcWithoutQueryParams);
    validateUrl(url);
  } catch (error) {
    throw new TableauError(EmbeddingErrorCodes.InvalidUrl, (error as Error).message);
  }

  try {
    url = VizUrl.create(srcWithoutQueryParams).withMode(urlMode).toURL();
  } catch (error) {
    // Syntactically valid URLs will be accepted even if they do not meet the requirements of a VizUrl.
  }

  const defaultParams = createDefaultParameters(url, embeddingId);

  const builder = new EmbeddingVizUrlBuilder(url)
    .appendDefaultParameters(defaultParams)
    .appendUserOptions(vizOptions)
    .appendFilters(filters)
    .appendParams(params)
    .appendCustomParams(customParams);

  // If api auth is enabled, we don't need to redirect to the auth endpoint as part of loading the Viz.
  if (vizOptions.iframeAuth) {
    builder.setToken(vizOptions.token);
  }

  return builder.build();
}

function createDefaultParameters(url: URL, embeddingId: number): ParametersMap {
  const defaultParameters: ParametersMap = new Map();
  defaultParameters.set(VizOptionNames.Embed, SANITIZED_VALUES.YES_VALUE);

  // This is used to tell the viz that it is embedded and who to talk to. Ideally
  // we will use a MessageChannel after the initial load so we don't need to dispatch
  defaultParameters.set(VizOptionNames.ApiID, `embhost${embeddingId}`);

  // TFS 1287448: Fix this Public hack
  if (url.hostname === 'public.tableau.com') {
    defaultParameters.set(VizOptionNames.ShowVizHome, SANITIZED_VALUES.NO_VALUE);
  }

  const internalVersion = `${INTERNAL_CONTRACT_VERSION.major}.${INTERNAL_CONTRACT_VERSION.minor}.${INTERNAL_CONTRACT_VERSION.fix}`;
  defaultParameters.set(VizOptionNames.ApiInternalVersion, internalVersion);

  const externalVersion = ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
  defaultParameters.set(VizOptionNames.ApiExternalVersion, externalVersion);

  // TODO: investigate nav values and make an enum showing acceptable values
  // used to manage sessions server-side
  defaultParameters.set(VizOptionNames.NavType, '0');
  defaultParameters.set(VizOptionNames.NavSrc, 'Opt');

  return defaultParameters;
}
