import { InternalApiDispatcher, VersionNumber } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry } from './ServiceRegistry';
import { AnimationServiceImpl } from './impl/AnimationServiceImpl';
import { AnnotationServiceImpl } from './impl/AnnotationServiceImpl';
import { DataSourceServiceImpl } from './impl/DataSourceServiceImpl';
import { ExportServiceImpl } from './impl/ExportServiceImpl';
import { ExternalContextMenuServiceImpl } from './impl/ExternalContextMenuServiceImpl';
import { FilterServiceImpl } from './impl/FilterServiceImpl';
import { GetDataServiceImpl } from './impl/GetDataServiceImpl';
import { NotificationServiceImpl } from './impl/NotificationServiceImpl';
import { ParametersServiceImpl } from './impl/ParametersServiceImpl';
import { SelectionServiceImpl } from './impl/SelectionServiceImpl';
import { SizeServiceImpl } from './impl/SizeServiceImpl';
import { VisualModelServiceImpl } from './impl/VisualModelServiceImpl';
import { VizServiceImpl } from './impl/VizServiceImpl';
import { ZoneServiceImpl } from './impl/ZoneServiceImpl';

export function registerAllSharedServices(dispatcher: InternalApiDispatcher, registryId: number, platformVersion?: VersionNumber): void {
  ApiServiceRegistry.get(registryId).registerService(new AnimationServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new DataSourceServiceImpl(dispatcher, registryId, platformVersion));
  ApiServiceRegistry.get(registryId).registerService(new ExportServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new GetDataServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new FilterServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new NotificationServiceImpl(dispatcher));
  ApiServiceRegistry.get(registryId).registerService(new ParametersServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new SelectionServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new AnnotationServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new ZoneServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new SizeServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new VisualModelServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new VizServiceImpl(dispatcher, registryId));
  ApiServiceRegistry.get(registryId).registerService(new ExternalContextMenuServiceImpl(dispatcher, registryId));
}
