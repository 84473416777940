import {
  ExecuteParameters,
  ExportCrosstabSheetMap,
  ExportFile,
  ExportPDFOptions as InternalExportPDFOptions,
  ParameterId,
  VerbId,
  VisualId,
} from '@tableau/api-internal-contract-js';
import {
  CrosstabFileFormat,
  ExportDataOptions,
  ExportPDFOptions as ExternalExportPDFOptions,
  ExportScenariosForPDFAndPowerPoint,
  SharedErrorCodes,
  SheetType as ExternalSheetType,
} from '@tableau/api-external-contract-js';
import { ExternalToInternalEnumMappings } from '../../EnumMappings/ExternalToInternalEnumMappings';
import { ExportHelpers } from '../../Utils/ExportHelpers';
import { ExportService } from '../ExportService';
import { ServiceNames } from '../ServiceRegistry';
import { ErrorHelpers } from '../../Utils/ErrorHelpers';
import { TableauError } from '../../TableauError';
import { ServiceImplBase } from './ServiceImplBase';

export class ExportServiceImpl extends ServiceImplBase implements ExportService {
  public get serviceName(): string {
    return ServiceNames.Export;
  }

  public getExportCrosstabSheetMapAsync(currentSheetType: ExternalSheetType): Promise<ExportCrosstabSheetMap> {
    const verb = VerbId.GetExportCrosstabSheetMap;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getExportCrosstabSheetMapAsync',
      [ParameterId.CurrentSheetType]: ExternalToInternalEnumMappings.sheetType.convert(currentSheetType),
    };
    return this.execute(verb, parameters).then<ExportCrosstabSheetMap>((response) => {
      const result = response.result as ExportCrosstabSheetMap;
      return result;
    });
  }

  public async exportCrosstabAsync(
    sheetName: string,
    format: CrosstabFileFormat,
    exportableWorksheetNames: Array<string>,
    currentSheetType: ExternalSheetType,
  ): Promise<ExportFile> {
    ErrorHelpers.verifySheetName(exportableWorksheetNames, sheetName);
    ErrorHelpers.verifyEnumValue<CrosstabFileFormat>(format, CrosstabFileFormat, 'CrosstabFileFormat');

    // The pres layer command for exporting Crosstab uses SimpleSheetIdentifiers instead of sheet names.
    // The extensions-and-embedding-api current does not store references to the SimpleSheetIdentifiers of its sheets
    // so we call a pres layer command that gives us the SimpleSheetIdentifier for each sheet name that appears in the
    // export Crosstab dialog popup.
    const sheetNameToIdMap = await this.getExportCrosstabSheetMapAsync(currentSheetType);
    if (!sheetNameToIdMap[sheetName]) {
      throw new TableauError(SharedErrorCodes.InternalError, 'missing sheet doc id from sheetMap');
    }

    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'exportCrosstabAsync',
      [ParameterId.SheetIdentifier]: sheetNameToIdMap[sheetName],
      [ParameterId.SendNotifications]: true, // send notification on pres layer that triggers file download
    };

    // pres layer command invoked depends on the crosstab file format
    let verb;
    switch (format) {
      case CrosstabFileFormat.CSV:
        verb = VerbId.ExportCrosstabCsvDownload;
        // CSV only parameter: see browser-clients/export-crosstab-options-dialog repo
        parameters[ParameterId.UseTabDelimiters] = true;
        break;
      case CrosstabFileFormat.Excel:
        verb = VerbId.ExportCrosstabExcelDownload;
        break;
      default:
        throw new TableauError(SharedErrorCodes.InternalError, 'unsupported Crosstab file format.');
    }

    return this.execute(verb, parameters)
      .then<ExportFile>((response) => {
        const result = response.result as ExportFile;
        return result;
      })
      .catch(() => {
        throw new TableauError(SharedErrorCodes.CrosstabCreationError, 'An unexpected error occurred while generating the document.');
      });
  }

  public exportDataAsync(visualId: VisualId, options: ExportDataOptions): Promise<void> {
    ErrorHelpers.verifyExportDataOptions(options);

    // Remove any duplicates from the input array
    const columnsAsSet: Set<string> = new Set(options.columnsToIncludeById);
    const columnsToIncludeById = Array.from(columnsAsSet);

    const verb = VerbId.ExportDataDownload;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'exportDataAsync',
      [ParameterId.VisualId]: visualId,
      [ParameterId.IgnoreAliases]: options.ignoreAliases ?? false,
      [ParameterId.ColumnsToIncludeById]: columnsToIncludeById,
    };

    return this.execute(verb, parameters)
      .then<void>(() => {})
      .catch(() => {
        throw new TableauError(SharedErrorCodes.DataCreationError, 'An unexpected error occurred while generating the document.');
      });
  }

  public exportPowerPointAsync(sheetNames: Array<string>, exportScenarios: ExportScenariosForPDFAndPowerPoint): Promise<ExportFile> {
    ErrorHelpers.verifySheetNamesForPDFAndPPT(sheetNames, exportScenarios);

    // Note: the ExportOriginUrl param is listed as optional for the pres layer command we are invoking; however,
    // recent changes were made that enforced the use of the empty string when invoking this command with no ExportOriginUrl.
    // see browser-clients/export-powerpoint-options-dialog repo.
    const verb = VerbId.ExportPowerpointDownload;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'exportPowerPointAsync',
      [ParameterId.ExportOriginUrl]: '',
      [ParameterId.SelectedSheetNames]: sheetNames,
    };

    return this.execute(verb, parameters)
      .then<ExportFile>((response) => {
        const result = response.result as ExportFile;
        return result;
      })
      .catch(() => {
        throw new TableauError(
          SharedErrorCodes.PowerPointCreationError,
          'An error occured while attempting to generate the PowerPoint file.',
        );
      });
  }

  public async exportPDFAsync(
    sheetNames: Array<string>,
    externalExportPdfOptions: ExternalExportPDFOptions,
    exportScenarios: ExportScenariosForPDFAndPowerPoint,
  ): Promise<ExportFile> {
    ErrorHelpers.verifyExportPDFOptions(externalExportPdfOptions);
    ErrorHelpers.verifySheetNamesForPDFAndPPT(sheetNames, exportScenarios);

    const internalExportPdfOptions: InternalExportPDFOptions = await this.getExportPDFOptionsAsync();
    ExportHelpers.updateInternalExportPDFOptions(internalExportPdfOptions, externalExportPdfOptions, sheetNames);

    const verb = VerbId.ExportPdfDownload;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'exportPDFAsync',
      [ParameterId.ExportPdfOptions]: internalExportPdfOptions,
    };

    return this.execute(verb, parameters)
      .then<ExportFile>((response) => {
        const result = response.result as ExportFile;
        return result;
      })
      .catch(() => {
        throw new TableauError(SharedErrorCodes.PDFCreationError, 'Unable to create PDF because something went wrong. Try again.');
      });
  }

  public getExportPDFOptionsAsync(): Promise<InternalExportPDFOptions> {
    const verb = VerbId.GetExportPdfOptions;
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getExportPdfOptionsAsync',
    };
    return this.execute(verb, parameters).then<InternalExportPDFOptions>((response) => {
      const result = response.result as InternalExportPDFOptions;
      return result;
    });
  }
}
