import { CustomParameter, EmbeddingErrorCodes, PulseSettings } from '@tableau/api-external-contract-js';
import { INTERNAL_CONTRACT_VERSION, PulseOptionNames } from '@tableau/api-internal-contract-js';
import { ApiVersion, TableauError } from '@tableau/api-shared-js';
import { EmbeddingUrlBuilder, ParametersMap, validateUrl } from './EmbeddingUrlBuilder';

export function createPulseUrl(src: string, options: PulseSettings, customParams: CustomParameter[]) {
  // strip params in URL, all custom params should come through 'pulseOptions', 'filters' or 'customParams'.
  const srcWithoutQueryParams = src.split('?')[0];

  let url: URL;
  try {
    url = new URL(srcWithoutQueryParams);
    validateUrl(url);
  } catch (error) {
    throw new TableauError(EmbeddingErrorCodes.InvalidUrl, (error as Error).message);
  }
  const defaultParams = createPulseDefaultParameters(url);
  const builder = new EmbeddingPulseUrlBuilder(url)
    .appendDefaultParameters(defaultParams)
    .appendUserOptions(options)
    .appendCustomParams(customParams);

  return builder.build();
}

function createPulseDefaultParameters(url: URL): ParametersMap {
  const defaultParameters: ParametersMap = new Map();
  const internalVersion = `${INTERNAL_CONTRACT_VERSION.major}.${INTERNAL_CONTRACT_VERSION.minor}.${INTERNAL_CONTRACT_VERSION.fix}`;
  defaultParameters.set(PulseOptionNames.ApiInternalVersion, internalVersion);
  defaultParameters.set(PulseOptionNames.embed, 'y');
  const externalVersion = ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
  defaultParameters.set(PulseOptionNames.ApiExternalVersion, externalVersion);
  defaultParameters.set(PulseOptionNames.PulseWebComponent, 'true');

  return defaultParameters;
}

export class EmbeddingPulseUrlBuilder extends EmbeddingUrlBuilder {
  public constructor(_url: URL) {
    super();
    this._url = _url;
    this._optionNames = PulseOptionNames;
  }

  /**
   * Sanitizes parameter values before they are added to the search params.
   * @param parameterName The name of the parameter. Some parameters require special handling.
   * @param value The raw value of the parameter.
   */
  protected sanitizeParameterValue(parameterName: string, value: unknown): string {
    return this.sanitizeValue(value);
  }
}
