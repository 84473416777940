/**
 * User supplied options that do not get passed as request parameters to load a Tableau viz, Tableau askdata
 */
export interface WebComponentLocalSettings {
  /**
   * The viz src
   */
  src?: string | null;

  /**
   * Represents width in pixels
   * Can be any valid CSS size specifier. If not specified, defaults to the published width of the view.
   */
  width?: string | number;

  /**
   * Represents height in pixels
   * Can be any valid CSS size specifier. If not specified, defaults to the published height of the view.
   */
  height?: string | number;
}

/**
 * This interface represents all of the options for configuring a webcomponent that are common between
 * viz and askdata.
 */
export interface WebComponentSettingsShared {
  /**
   * The token used for authorization
   *
   * ```
   * <tableau-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-authoring-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-ask-data id="tableauAskData" token="some-token-containing-clientId" />
   * ```
   */
  token?: string;

  /**
   * Indicates whether the non-minified version of JavaScript is loaded. If specified (or set to true), the
   * non-minified version is used for both the local component and the Tableau Server visualization (if enabled).
   * If not specified (or set to false), the minified version of the JavaScript files are loaded.
   *
   * ```
   * <tableau-viz id="tableauViz" debug />
   * <tableau-authoring-viz id="tableauViz" debug />
   * <tableau-ask-data id="tableauAskData" debug />
   * ```
   */
  debug?: boolean;

  /**
   * Indicates whether to use the old auth mechanism for authentication which happens inside the iframe. If specified, VizLoadErrorEvents
   * triggered due to auth failures will not be thrown.
   *
   * ```
   * <tableau-viz id="tableauViz" iframe-auth />
   * <tableau-authoring-viz id="tableauViz" iframe-auth />
   * <tableau-ask-data id="tableauAskData" iframe-auth />
   * ```
   */
  iframeAuth?: boolean;
}

/**
 * The names of the common string constants you can use as HTML attributes for the tableau web component.
 * @ignore
 */
export enum WebComponentAttributes {
  Src = 'src',
  Width = 'width',
  Height = 'height',
  Token = 'token',
  Debug = 'debug',
  IframeAuth = 'iframe-auth',
  OnVizLoadError = 'onvizloaderror',
  ForceTokenSync = 'force-token-sync',
}

/**
 * The names of the string constants you can use as child tags for TableauViz, TableauAuthoringViz and TableauAskData custom elements.
 * @ignore
 */
export enum WebComponentChildElements {
  /**
   * Contains a list of custom parameters.  Available in viz viewing, viz authoring and askdata.
   */
  CustomParameter = 'custom-parameter',
}

export enum WebComponentChildElementAttributes {
  Field = 'field',
  Value = 'value',
  Name = 'name',
}
